@font-face {
    font-family: VinciSansRegular;
    src: url(../fonts/vinci_sans_regular.woff2) format("woff2");
}
@font-face {
    font-family: VinciSansBold;
    src: url(../fonts/vinci_sans_bold.woff2) format("woff2");
}
@font-face {
    font-family: VinciSansCondensedMedium;
    src: url(../fonts/vinci_sans_condensed-medium.woff2) format("woff2");
}