/*----------------------------------
// Fonts
//----------------------------------*/
@import '../assets/scss/font.scss';

/* @font-face {
    font-family: VinciSansRegular;
    src: url(../assets/fonts/VinciSans-Regular.otf) format("opentype");
}
@font-face {
    font-family: VinciMedium;
    src: url(../assets/fonts/VinciSans-Medium.otf) format("opentype");
}
@font-face {
    font-family: VinciSansBold;
    src: url(../assets/fonts/VinciSans-Bold.otf) format("opentype");
}
@font-face {
    font-family: VinciSansCondensedMedium;
    src: url(../assets/fonts/VinciSansCondensed-Medium.otf) format("opentype");
} */

.primary-color-bgr {background-color: #237797;}
.accent-color-bgr {background-color: #ED1C2E;}
disabled-color-bgr {background-color: #aca095;}
.primary-color {color: #237797;}
.accent-color {color: #ED1C2E;}
disabled-color {color: #aca095;}

body {
    font-family: VinciSansRegular,"Trebuchet MS",Arial,Helvetica,sans-serif;
    color: #000;
    font-size: 1rem;
    background: #fff;
}
* {
    font-size: 100%;
    font-family: VinciSansRegular,"Trebuchet MS",Arial,Helvetica,sans-serif;
    letter-spacing: 1px;
}

/* color bar -> #EDEDED
color text -> #237797 */

/* Linked header */
h1, h2, .ariane {
	font-family: VinciCondensedMedium,"Trebuchet MS",Arial,Helvetica,sans-serif;
}
/* Linked text */
.text h2 a, .text a {
    color: #237797 !important;
}

/* Date format, first part is bold */
/* 29/09/2019 8:30 */
.box strong {
    font-family: VinciSansBold,"Trebuchet MS", Arial,Helvetica,sans-serif;
    font-weight: normal;
}

/* =========== Toolbar ============*/
.mat-toolbar {
    border: 1px solid lightgray;
}
.mat-toolbar.mat-primary {
    background-color: white;
}
.navbar-brand {
    color: #666;
}
/* header (it uses the body font family) */
/* font-family: VinciSansRegular,"Trebuchet MS",Arial,Helvetica, sans-serif; */
.languges a {
    display: inline-block;
    padding: 0 0.5rem;
}
.toolbar a {
    color: #666;
}
.toolbar {
	text-align: left;
    font-size: 1.125rem;
}
.toolbar div {
    display: inline-block;
    vertical-align: top;

}
#page_header {
    font-family: VinciSansRegular, "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
}

/* ================================*/

mat-sidenav .mat-list-text a {
    font-family: VinciSansBold, "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 1.250rem !important;
}
mat-sidenav { width: 250px; }
/* Menu link */

@media only screen and (max-width: 900px){
    .logo-mobile {
        background: rgba(255,255,255,1);
    }
}
@media only screen and (max-width: 900px){
    .logo-mobile {
        display: block;
        border-bottom: solid thin #EDEDED;
    }
} 


/* ============ Button colors ============ */
.mat-fab.mat-accent, .mat-flat-button.mat-accent, .mat-mini-fab.mat-accent, .mat-raised-button.mat-accent {
    background-color: #ED1C2E;
}
.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
    background-color: #237797;
}
.fab-menu .fab-toggle {
    background-color: #ED1C2E !important;
}
body .ui-button {
    background-color: #237797;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background-color: #237797;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
    color: #237797;
}
.mat-fab.mat-accent[disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled] {
    background-color: #aca095;
    color: #595046
}
/* ======================================= */

.form_header {
    color: #237797 !important;
}